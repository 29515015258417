// Generic imports
import React, { Component } from "react"

// Other components imports
import { Styles } from "components/LandingPage/Description/style"

class Description extends Component {
  render() {
    return (
      <Styles>
        <div className="row">
          <div className="desc">
            <h1>A safe place to grow. For all students.</h1>
            <h6>Welcome to TamanSchool!</h6>
            <p>This is the place where passion meets dedication. A decentralised platform that is built for students who are curious to discover who they are and also for students who want to give back to society by teaching. By creating a psychologically safe place to grow, providing heart-and-mind-nurturing content, and inclusive access for all. We believe with the right learning ecosystem, <b>anyone</b> can flourish.</p>
          </div>
        </div>  
      </Styles>
    )
  }
}

export default Description

// Generic imports
import styled from "styled-components"

// Static imports
import bgTalamus from "images/bg-talamus.png"
import mobileTalamus from "images/mob-frame-tala.png"

export const Styles = styled.section`
  margin: 48px;
  background: #F7F6F4;
  min-height: 541px;
  
  .comingsoon {
    background: url(${bgTalamus});
    border-radius: 16px;
    background-size: cover;
    min-height: 541px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 84px 101px;
  }

  .comingsoon h5 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    color: #DDAC38;
  }

  .talamus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 19px;
    width: 622px;
    height: 106px;
    margin-top: 56.95px;
  }

  .talamus h4 {
    width: 622px;
    height: 43px;

    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    letter-spacing: 0.015625em;
    color: #FFFEFC;
  }

  .talamus p {
    width: 600px;
    height: 44px;

    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03125em;

    color: rgba(255, 255, 255, 0.75);

  }
  @media (max-width: 499px) {
    .comingsoon {
      background: url(${mobileTalamus});
      background-size: cover;
      padding: 30px;
    }

    .talamus, .talamus h4, .talamus p {
      width: auto;
    }
  }

  @media (max-width: 1025px) and (min-width: 500px) {
    .comingsoon {
      padding: 84px;
    }

    .talamus p {
      width: 400px;
    }
  }
`

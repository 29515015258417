// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.section`
  padding: 69px 0;
  gap: 32px;
  left: 0px;

  /* position: absolute; */
  // width: 1439px;
  /* height: 795px; */
  /* top: 4292.05px; */
  background: 
  radial-gradient(70.69% 69.41% at 98.06% 3.33%, #252525 0%, rgba(70, 70, 70, 0) 100%), 
  radial-gradient(115.7% 115.7% at 0% 101.89%, #0F0F0F 0%, #464646 100%);

  .text-above {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    margin: 0 auto 32px auto ;

    width: 1051px;
    height: 86px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .text-above h5 {
    width: 1051px;

    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FFFEFC;
  }

  .text-above p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03125em;

    color: #FFFEFC;
  }

  .card-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1036px;
    margin: 0 auto;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    gap: 32px;
  }

  .card-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 21px;
    left: 0%;
    right: 68.73%;
    top: 0%;
    bottom: 0%;
    background: #FFFEFC;
    box-shadow: 0px 8px 20px rgba(0,0,0,0.1);
    border-radius: 16px;
    width: 324px;
    height: 472px;
  }

  .card-value img {
    width: 80px;
    height: 80px;
  }

  .card-value h5 {
    width: 260px;
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    color: #333333;
  }

  .card-value p {
    width: 260px;
    height: 110px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0094em;

    color: #818181;
  }

  .addition-area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    margin: 0 auto;

    width: 1051px;
    height: 35px;

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }

  .contact-us {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 4px;

    border-radius: 6px;
  }

  .contact-us img {
    width: 16px;
    height: 16px;

    left: 8.54%;
    right: 8.54%;
    top: 8.33%;
    bottom: 8.34%;
  }

  .contact-us p {
    width: 175px;
    height: 19px;

    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0.0156em;

    color: #FFFFFF;
  }

  .link-createclass {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px;
    gap: 4px;
    width: 792px;

    border-radius: 6px;

    flex: none;
    order: 1;
    flex-grow: 1;
  }

  .link-createclass p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: 0.0156em;
    height: 19px;
    padding: 0 16px;

    color: #FFFFFF;
  }

  @media (max-width: 1025px) {
    padding: 69px 44px;
    width: auto;

    .text-above {
      width: auto;
      height: auto;
    }

    .card-box {
      display: block;
      width: auto;
    }

    .card-value {
      height: auto;
      margin: 10px auto;
    }

    .card-value p {
      width: auto;
      height: auto;
    }

    .contact-us {
      display: -webkit-inline-box;
      display: inline-flex;
    }

    .addition-area, .link-createclass {
      display: inline;
      padding: 0;

    }
  }
`
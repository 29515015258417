// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.section`
  padding-bottom: 100px;
  background: #ffffff;

  .judul {
    top: calc(50% - 30px/2 - 469.5px);

    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    color: #000000;
    margin-bottom: 30px;
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-class {
    margin: 1%;
    width: calc(25% - 2%);
    max-width: 320px;
    min-height: 280px;
  }

  @media (max-width: 480px) {
    .card-list {
      justify-content: center;
    }

    .card-class {
      margin: 2%;
      width: calc(100% - 4%);
    }
  }

`
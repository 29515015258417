// Generic imports
import styled from "styled-components"

// Static imports
import dog from "images/dog.png"

export const Styles = styled.section`
  background-image: url(${dog});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 50%;
  padding-left: 120px;
  padding-right: 96px;
  padding-top: 50px;
  padding-bottom: 100px;
  min-height: 541px;
  margin-bottom: 80px;

  .desc {
    padding-bottom: 40px;
    max-width: 50%;
    margin-top: 120px;
    /* margin: auto; */
  }

  .desc h1 {
    font-family: 'Source Serif Pro';
    font-size: 54px;
    font-weight: 700;
    max-width: 548px;
  }

  .desc p {
    font-family: 'Avenir';
    font-size: 16px;
    font-weight: 900;
    margin-top: 20px;
    max-width: 500px;
    color: #666666;
  }


  @media (max-width: 767px) {
    padding: 40px;
    background-size: 75%;
    .desc {
      margin: 0;
      max-width: 80%;
      padding-bottom: 20px;
    }

    .desc h1 {
      font-size: 40px;
    }
  }

  @media (max-width: 1025px) and (min-width: 500px) {
    background-size: 50%;
  
    .desc {
      margin: 0;
      max-width: 60%; 
    }
  }
`

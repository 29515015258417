// Generic imports
import React, { Component } from "react"

// Other components imports
import { Styles } from "components/LandingPage/Products/style"

import tcSeikhlasnya from "../../../images/tamanClassSeikhlasnya.png"
import tcPlus from "../../../images/tamanClass+.png"
import roadmap from "../../../images/roadmap.png"
import laptop from "../../../images/MacBook Pro.png"
import hands from "../../../images/hands.png"
import blueNotion from "../../../images/blue-notion.png"
import book from "../../../images/book.png"
import choice from "../../../images/choice.png"
import classImg from "../../../images/class.png"
import notion from "../../../images/notion.png"
import pay from "../../../images/pay.png"
import people from "../../../images/people.png"
import tutor from "../../../images/tutor.png"
import upgrade from "../../../images/upgrade.png"

const ways1 = [
  { photo: hands, desc: "Collective payment method" },
  { photo: tutor, desc: "Tutors from your dream school or company" },
  { photo: book, desc: "More suitable and deeper content" },
]
const ways2 = [
  { photo: classImg, desc: "Smaller class. Less anxiety" },
  {
    photo: notion,
    desc: "Powered by Talamus, a Notion-based learning management system",
  },
]
const ways3 = [
  { photo: pay, desc: "Pay as you wish" },
  { photo: choice, desc: "An abundance of choice of Classes." },
  { photo: people, desc: "Meet a lot of new people that share your passion." },
]
const ways4 = [
  {
    photo: upgrade,
    desc:
      "Upgrade the services as your needs grow. More Ikhlas, more Up Class.",
  },
  {
    photo: blueNotion,
    desc: "Powered with Talamus. A Notion based learning management system.",
  },
]

class Products extends Component {
  render() {
    return (
      <Styles>
        <div className="judul">
          <hr />
          <h5>Products for Student.</h5>
          <hr />
        </div>

        <div className="product-section">
          <div className="card">
            <img src={tcPlus} alt="Logo" className="logo" />

            <div className="product-img">
              <img src={roadmap} alt="roadmap" className="roadmap-img" />
              <img src={laptop} alt="meeting" className="laptop-img" />
            </div>

            <div className="prod">
              <h1>A complete learning</h1>
              <h1>ecosystem. A complete you.</h1>
              <p>
                The ultimate choice of Classes that suits your need. Preparing
                for a prominent school? Why don’t you learn directly from their
                students or alumni? The same goes for your dream company or
                organisation. We can provide all that while giving you the most
                versatile digital learning package using Notion. Entering Zoom,
                writing your diary and learning feedback, reading materials,
                taking quizzes, initiating something with your group, anything!
                TamanClass+ helps you express your natural best in the safest
                and most customisable way possible.{" "}
              </p>
            </div>

            <div className="way">
              <p>How does it help student?</p>

              <div className="block-img">
                {ways1.map(w => {
                  return (
                    <div key={w.id} className="card-img">
                      <img src={w.photo} className="small-img" />
                      <p>{w.desc}</p>
                    </div>
                  )
                })}
              </div>

              <div className="block-img">
                {ways2.map(w => {
                  return (
                    <div key={w.id} className="card-img2">
                      <img src={w.photo} className="small-img" />
                      <p>{w.desc}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="card radial-blue">
            <img src={tcSeikhlasnya} alt="Logo" className="logo" />

            <div className="product-img">
              <img src={roadmap} alt="roadmap" className="roadmap-img" />
              <img src={laptop} alt="meeting" className="laptop-img" />
            </div>

            <div className="prod kanan">
              <h1>Learn what you love.</h1>
              <h1>Pay as you wish.</h1>
              <p>
                Find your spark without boundaries. Try as many Classes as
                possible without worrying much about payment. Enjoy live
                streaming Classes for free. Or pay Seikhlasnya to get more
                services like the video record, summarised text, community
                groups, and personalised mentorship. TamanClass Seikhlasnya
                defines our commitment to democracy in education. Served with
                quality. Built for equality.{" "}
              </p>
            </div>

            <div className="way">
              <p>How does it help student?</p>

              <div className="block-img">
                {ways3.map(w => {
                  return (
                    <div key={w.id} className="card-img blue">
                      <img src={w.photo} className="small-img" />
                      <p>{w.desc}</p>
                    </div>
                  )
                })}
              </div>

              <div className="block-img">
                {ways4.map(w => {
                  return (
                    <div key={w.id} className="card-img2 blue">
                      <img src={w.photo} className="small-img" />
                      <p>{w.desc}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Styles>
    )
  }
}

export default Products

// Generic imports
import React, { Component } from "react"

// Other components imports
import { Styles } from "components/LandingPage/TrendingClass/style"
import NewClassCard from "components/Classes/NewClassCard"

// utils
import { getAllTrendingClasses } from "services/kelas"
import { isPastDate } from "services/utils"

// static imports
class TrendingClass extends Component {
  constructor(props) {
    super(props)

    this.state = {
      classes: [],
      // DataisLoaded : false
    }
  }

  componentDidMount() {
    getAllTrendingClasses().then(resp => {
      const data = resp.data.map(val => val.kelas)
      data?.sort((a, b) => {
        const aClosed = isPastDate(new Date(a.registration_due))
        const bClosed = isPastDate(new Date(b.registration_due))
        if (!aClosed && bClosed) return -1
        if (aClosed && !bClosed) return 1
        if (a.is_verified && !b.is_verified) return -1
        if (!a.is_verified && b.is_verified) return 1
        return 0
      })
      this.setState({
        classes: data,
      })
    })
  }

  render() {
    const { classes } = this.state
    return (
      <Styles>
        <h1 className="judul text-center">Trending Classes</h1>

        <div className="w-100 card-list">
          {classes.slice(0, 8).map((kelas, id) => {
            const onlyStarRating = kelas.rating.map(e => e.bintang)
            const allRating =
              onlyStarRating.reduce((prev, curr) => prev + curr, 0) /
              onlyStarRating.length

            const classPrice =
              kelas.paket_pembayaran?.length > 0
                ? kelas.paket_pembayaran.reduce((prev, curr) => {
                    const prevPrice = prev.harga_per_orang_sesi
                    const currPrice = curr.harga_per_orang_sesi
                    return prevPrice > currPrice ? curr : prev
                  }).harga_per_orang_sesi
                : 0

            const classMember =
              kelas.paket_pembayaran?.length > 0
                ? kelas.paket_pembayaran.reduce(
                    (prev, curr) => prev + curr.banyak_pendaftar,
                    0
                  )
                : 0

            return (
              // <div key={kelas.id}>
              <NewClassCard
                key={id}
                className="card-class"
                code={kelas.slug}
                image={kelas.logo}
                type={kelas.type}
                title={kelas.name}
                author={kelas.owner.first_name + " " + kelas.owner.last_name}
                description={kelas.description}
                category={kelas.category}
                tags={kelas.tags}
                price={classPrice}
                member={classMember}
                rating={allRating ? allRating.toFixed(1) : "-"}
              />
            )
          })}
        </div>
      </Styles>
    )
  }
}

export default TrendingClass

// Generic imports
import React, { Component } from "react"
import { connect } from "react-redux"

// Other components imports
// import Button from "components/Button"
import Introducing from "components/LandingPage/Introducing"
import Description from "components/LandingPage/Description"
import Products from "components/LandingPage/Products"
import ComingSoon from "components/LandingPage/ComingSoon"
import ClassMaker from "components/LandingPage/ClassMaker"
import TrendingClass from "components/LandingPage/TrendingClass"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"

// Static imports

// Utils imports
// import { getName } from "services/auth"
import { trackLandingPageViewed } from "trackers/amplitude"
import { Styles } from "./style"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

class LandingPage extends Component {
  componentDidMount() {
    trackLandingPageViewed()
  }

  render() {
    // const { user } = this.props
    // const isLoggedIn = Boolean(user.email)

    return (
      <Styles>
        <div>
          <Introducing />
          <Description />
          <TrendingClass />
        </div>
        <div className="grey-bg">
          <Products />
          <ComingSoon />
          <ClassMaker />
        </div>
      </Styles>
    )
  }
}

export default withConnect(LandingPage)

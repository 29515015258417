// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.section`
  padding-top: 50px;
  padding-bottom: 100px;
  min-height: 541px;
  background: #F7F6F4;
  margin: 0 48px;

  .judul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 23px;
    margin-bottom: 40px;
  }

  .judul hr {
    // width: 480px;
    width: calc(100% - 65%);
    height: 0px;
    border: 0.5px solid #818181;
  }

  .judul h5 {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #818181;

  }

  .product-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4%;
    /* gap: 12px; */
    border: none !important;

    // width: 665.5px;
    width: calc(100% - 60%);
    height: 1384.05px;
    background: radial-gradient(68.31% 177.15% at 80.82% 88.49%, #E4FFE1 0%, #FFFEFC 100%);
    border-radius: 16px;
 
    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .radial-blue {
    background: radial-gradient(68.31% 177.15% at 80.82% 88.49%, #E4EDFB 0%, #FFFEFC 100%) !important;
  }

  .img-wrapper {
    max-width: 225px;
    margin: auto;
    padding-bottom: 15px;
  }

  .tc-logo {
    left: 49px;
    top: 64px;
  }

  .product-img {
    width: 537.5px;
    height: 379.05px;
  }

  .roadmap-img {
    position: absolute;
    width: 75%;
    top: 175px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
  }

  .prod h1 {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    letter-spacing: 0.015625em;
    color: #333333;
  }

  .prod p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03125em;
    color: rgba(51, 51, 51, 0.75);
  }

  .laptop-img {
    position: absolute;
    width: 85%;
    left: 10%;
    top: 18%;
  }

  .way p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.0156em;
    color: #3B3B3B;
  }

  .block-img {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    width: 504px;
    margin-bottom: 12px;
  }

  .way .card-img2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 24px; */
    gap: 10px;

    width: 240px;
    height: 156px;

    background: rgba(230, 230, 230, 0.25);
    border-radius: 12px;
  }

  .way .card-img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 152px;
    height: 178px;
    background: rgba(230, 230, 230, 0.25);
    border-radius: 12px;
  }

  .blue {
    background: rgba(0, 118, 162, 0.05) !important;
  }

  .way .small-img {
    width: 32px;
    height: 32px;
    margin: 24px 96px 10px 24px;
  }

  .card-img p {
    width: 104px;
    height: 66px;
    margin: 0 24px;

    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03125em;
  }

  .card-img2 p {
    width: 192px;
    height: 66px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.03125em;

    color: #3B3B3B;
    margin: 0 24px;
  }

  @media (max-width: 1200px) and (min-width: 1026px) {
    .way .card-img {
      width: 27%;
    }
    .card-img p, .card-img2 p {
      margin: 0 auto;
    }

    .way .card-img2 {
      width: 43%;
    }

    .product-img {
      height: 35% !important;
    }
  }

  @media (max-width: 499px) {
    margin: 0 28px;
    padding-bottom: 0;

    .prod {
      margin-top: 100px;
    }

    .judul hr {
      display: none;
    }

    .way, .block-img {
      display: block;
      width: auto;
      margin: 0 auto;
    }

    .card {
      padding: 34px;
      width: 100%;
      height: auto;
    }

    .card-img, .card-img2 {
      margin: 10px auto;
    }

    .way .card-img {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      width: 240px;
      height: 156px;

      background: rgba(230, 230, 230, 0.25);
      border-radius: 12px;
    }

    .card-img p {
      width: 192px;
      height: 66px;

      font-family: 'Avenir';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.03125em;

      color: #3B3B3B;
      margin: 0 24px;
    }

    .logo {
      width: 75%;
    }

    .product-section {
      display: block;
    }

    .prod h1 {
      width: auto;
    }

    .roadmap-img {
      width: 70%;
      height: auto;
      left: 44px;
      top: 100px;
    }

    .laptop-img {
      width: 75%;
      height: auto;
      left: 64.96px;
      top: 150px;
  }

  .product-img {
    height: 179.05px;
  }
}

  @media (max-width:1025px) and (min-width: 500px) {
    margin: 0 28px;
    padding-bottom: 0;

    .judul hr {
      display: none;
    }
    
    .product-section {
      display: block;
    }

    .card {
      width: auto;
      margin: 20px auto;
    }

    .prod {
      margin-top: 35%;
    }

    .way {
      margin: 0 auto;
    }
  }

  @media (min-width: 1250px) {
    .prod {
      margin-top: 150px;
    }
  }
`

// Generic imports
import React, { Component } from "react"

// Other components imports
import { Styles } from "components/LandingPage/ComingSoon/style"

// static imports

class ComingSoon extends Component {
  render() {
    return (
      <Styles>
        <div className="comingsoon">
          <h5>Coming Soon</h5>
          <div className="talamus">
          <h4>Talamus</h4>
            <p>TamanScool’s Learning Management System is powered by Notion. 
                <b> Empowering every unique learning need.</b></p>
          </div>
        </div> 
      </Styles>
    )
  }
}

export default ComingSoon

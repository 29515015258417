// Generic imports
import styled from "styled-components"

// Static imports
import freedom from "images/freedom.png"

export const Styles = styled.section`
  background-image: url(${freedom});
  background-repeat: no-repeat;
  background-position: top left;
  padding-left: 39px;
  padding-right: 100px;
  padding-bottom: 100px;
  padding-top: 106px;
  min-height: 541px;
  background-color: #ffffff;

  .desc {
    margin-left: auto; 
    margin-right: 0;
    max-width: 50%;
    margin-top: -5%;
  }

  .desc h1 {
    font-family: "Source Serif Pro";
    font-size: 48px;
    font-weight: 700;
  }

  .desc h6 {
    font-family: "Avenir";
    font-size: 20px;
    font-weight: 900;
    margin-top: 36px;
  }

  .desc p {
    font-family: "Avenir";
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    color: rgba(51, 51, 51, 0.75);
  }

  @media (max-width: 499px) {
    background-size: 75%;
    padding-top: 270px;
    padding-left: 0;
    padding-right: 0;

    .desc {
      margin-right: auto;
      max-width: 80%;
    }
  }

  @media (max-width: 1025px) and (min-width: 500px) {
    background-size: 45%;
    padding-top: 0;
  }
`

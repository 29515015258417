// Generic imports
import React from "react"

// Other components imports
import LandingPage from "components/LandingPage"
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports

const IndexPage = () => (
  <Layout>
    <SEO title="Platform Les Tutorial Online terbaik di Indonesia" />
    <LandingPage />
  </Layout>
)

export default IndexPage

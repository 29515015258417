// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  background: #FFFFFF;

  .grey-bg {
    background: #F7F6F4 !important;
  }

  .welcome-back {
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 50px;
    padding-bottom: 50px;
    background: #fafafa;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
  }

  .welcome-back-left {
    width: calc(100% - 240px);
  }

  .welcome-back-left h1 {
    padding-top: 5px;
  }

  .welcome-back-right {
    width: 240px;
    padding-top: 15px;
  }

  .welcome-back-right button {
    width: 100%;
    color: #51b848;
    background: transparent;
  }

  @media (max-width: 767px) {
    .welcome-back {
      flex-wrap: wrap;
      text-align: center;
    }

    .welcome-back-left {
      width: 100%;
    }

    .welcome-back-right {
      width: 80%;
      margin: auto;
    }
  }
`

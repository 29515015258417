// Generic imports
import React, { Component } from "react"

// Other components imports
import Button from "components/Button"
// import Image from "components/image"
import { Styles } from "components/LandingPage/Introducing/style"
// import { Row } from "react-bootstrap"

// import { sendAmplitudeData } from "trackers/amplitude"

class Introducing extends Component {
  render() {
    return (
      <Styles>
        <div>
            <div className="row">
              <div className="desc">
                <h1>Make Learning Feels Safe. Together!</h1>
                <p>TamanSchool is a platform where students can teach other students to make a better and safer learning experience.</p>
              </div>
            </div>

              <div className="row" style={{ gap: "1.5rem" }}>
                  <Button to="/classes">Discover Class You Love</Button>
                  <Button className="secondary" to="/create-class">Build Class People Love</Button>
              </div>
            </div>
      </Styles>
    )
  }
}

export default Introducing

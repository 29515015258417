// Generic imports
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import {navigate} from 'gatsby';
import { Redirect } from '@reach/router'

// Other components imports
import { Styles } from "components/LandingPage/ClassMaker/style"

// static imports
import getStudents from "../../../images/getStudents.png"
import getIncome from "../../../images/getIncome.png"
import personalize from "../../../images/personalize.png"
import whiteWA from "../../../images/white-whatsapp.png"

import * as sessionSelectors from "rdx/slices/session/selectors"

const values = [
  {
    photo: getStudents,
    title: "Get students",
    desc:
      "Be connected and build meaningful relationships with the community of passionate learners across Indonesia and around the world.",
  },
  {
    photo: getIncome,
    title: "Generate income",
    desc:
      "Gain one of the highest commissions for creators online for up to 70% of the income. Making teaching, a prestigious and noble activity like it's supposed to be.",
  },
  {
    photo: personalize,
    title: "Personalize your ecosystem",
    desc:
      "There's no one size fits in education. You can help your students grow the way they naturally do with various learning-oriented features in TamanSiswa. Make your class versatile and impactful for the life of your students.",
  },
]

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}
const withConnect = connect(mapStateToProps, mapDispatchToProps)

const ClassMaker = props => {
  const [redirectTo, setRedirectTo] = useState(null)
  useEffect(() => {
    setRedirectTo(encodeURIComponent(window.location.href))
  }, [])

  const { user } = props
  const isLoggedIn = Boolean(user.email)

  return (
    <Styles>
      <div className="text-above">
        <h5>Be A ClassMaker.</h5>
        <p>
          The power to change education is in your hands! Whether you are a
          designer in a company, a book author, a literature student, a
          scientist, or anything, you can help students explore their passion.{" "}
        </p>
      </div>
      <div className="card-box">
        {values.map(v => {
          return (
            <div key={v.id} className="card-value">
              <img src={v.photo} />
              <h5>{v.title}</h5>
              <p>{v.desc}</p>
            </div>
          )
        })}
      </div>

      <div className="addition-area">
        <Link to="/contact-us">
          <div className="contact-us">
            <img src={whiteWA} alt="whatsapp" />
            <p>Contact us to learn More</p>
          </div>
        </Link>
        <div className="link-createclass">

          {isLoggedIn ? (
            <Link to="/tutor-dashboard">
              <p>Create my class now</p>
            </Link>
          ) : (
            <Link
              to={`/login/?redirect=${encodeURIComponent("/tutor-dashboard")}`}
            >
              <p>Create My Class Now</p>
            </Link>
          )}

        </div>
        {/* </Link> */}
      </div>
    </Styles>
  )
}

export default withConnect(ClassMaker)
